import Vue from 'vue'
import VueRouter from 'vue-router'
import Dash from '../views/Dash.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/user_signup/:token',
    name: 'UserSignup',
    component: () => import(/* webpackChunkName: "home" */ '../views/UserSignup.vue')
  },
  {
    path: '/',
    name: 'Dash',
    component: Dash,
    children: [
      {
        path: '/admin',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/permission',
        name: 'Permission',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Permission.vue')
      },
      {
        path: '/role',
        name: 'Role',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Role.vue')
      },
      {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/User.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = window.localStorage.getItem('token') !== null
  console.log(window.localStorage.getItem('token'))
  console.log(isAuthenticated)
  console.log(typeof window.localStorage.getItem('token'))
  if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

if (window.localStorage) {
  var localUserString = window.localStorage.getItem('user') || 'null'
  var localUser = JSON.parse(localUserString)

  if (localUser && store.state.session !== localUser) {
    store.dispatch('session/setUser', localUser)
    store.dispatch('session/setToken', window.localStorage.getItem('token'))
  }
}

export default router
